@tailwind base;
@tailwind components;
@tailwind utilities;
.btn{
    @apply outline-none focus:outline-none px-3 py-2 bg-white rounded-sm flex items-center min-w-fit hover:bg-gray-100;
}
.customspan{
    @apply lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-gray-400 items-center justify-center hover:bg-gray-900 hover:text-white;
}
.btn1{
    @apply w-full hover:text-primary transform hover:scale-105 rounded py-2 px-4 mx-2;
}
.btn2{
    @apply rounded-full py-2 px-3 text-xs font-bold cursor-pointer tracking-wider uppercase bg-primary text-white hover:text-secondary-grayel hover:shadow-inner transform hover:scale-125;
}
.btn3{
    @apply w-full rounded-sm py-2 px-3 text-xs font-bold cursor-pointer tracking-wider uppercase bg-primary text-white hover:text-secondary-grayel hover:shadow-inner transform hover:scale-110;
}
